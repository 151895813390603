import React from 'react'
// import { navigate } from 'gatsby'
import BannerTextOnly from 'blocks/BannerTextOnly/template'
import CreateAccount from 'blocks/CreateAccount/template'
import Seo from 'components/Seo'

import { LocaleContext } from 'gatsby-theme-i18n'

const Page = () => {
  const locale = React.useContext(LocaleContext)

  // if already logged in, go to welcome
  // if (typeof sessionStorage !== 'undefined') {
  //   if (sessionStorage.getItem('user')) {
  //     const localePrefix = locale === 'en' ? '/en' : ''
  //     navigate(`${localePrefix}/account/welcome`)
  //   }
  // }

  const strings = {
    en: {
      accounts: 'Accounts',
      createAccount: 'Create Account',
    },
    zh: {
      accounts: '账户',
      createAccount: '创建账户',
    },
  }
  const tr = strings[locale]

  const seo = {
    title: `${tr.accounts} - ${tr.createAccount}`,
  }
  const blockContent = {
    bannerTextOnly: {
      pretitle: tr.accounts,
      pretitleLink: '/account',
      title: tr.createAccount,
    },
  }

  return (
    <>
      <Seo content={seo} />
      <BannerTextOnly
        content={blockContent.bannerTextOnly}
        padBottomLess={true}
      />
      <CreateAccount />
    </>
  )
}

export default Page
