import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import ButtonLink from 'components/ButtonLink'
import LinkWrap from 'components/LinkWrap'
import LoaderSvg from 'assets/svg/ui/loader.svg'
import { LocaleContext } from 'gatsby-theme-i18n'
import { navigate } from 'gatsby'
import * as styles from './styles.module.scss'
import axios from 'axios'
import { objToFormData } from 'js/utils'

const Component = () => {
  const [postedEmail, setPostedEmail] = useState('')
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const locale = React.useContext(LocaleContext)

  const strings = {
    en: {
      email: 'Email',
      password: 'Password',
      errorEmailExists: `Error: ${postedEmail} already registered.`,
      backToLogin: 'Back to login',
      submit: 'Submit',
    },
    zh: {
      email: '邮箱',
      password: '密码',
      errorEmailExists: `提示：${postedEmail} 已注册。`,
      backToLogin: '返回登陆',
      submit: '提交',
    },
  }
  const tr = strings[locale]

  const sendEmail = (user) => {
    const data = {
      email: user.email,
      locale,
      isEmailLive: process.env.GATSBY_IS_EMAIL_LIVE,
      siteUrl: process.env.GATSBY_SITE_URL,
    }
    const formData = objToFormData(data)

    const url = `${process.env.GATSBY_EMAIL_URL}/welcome/email.php`

    axios.post(url, formData).then((response) => {})
  }

  const onSubmit = (values) => {
    setError(null)
    setIsLoading(true)
    setTimeout(() => {
      setPostedEmail(values.email)
    }, 500)

    const errorEmailExists = () => {
      setError(null)
      setTimeout(() => {
        setError('emailExists')
        setIsLoading(false)
      }, 500)
    }
    const success = (user) => {
      setError(null)
      setIsLoading(false)
      // send the welcome email
      sendEmail(user)
      // log in the user
      logIn(user)
    }
    const logIn = (user) => {
      sessionStorage.setItem('user', user.email)
      const localePrefix = locale === 'en' ? '/en' : ''
      navigate(`${localePrefix}/account/welcome`)
    }

    const url = process.env.GATSBY_PHP_URL

    const data = {
      function: 'createUser',
      email: values.email,
      password: values.password,
    }
    const formData = objToFormData(data)

    axios.post(url, formData).then((response) => {
      if (response.data === 'errorEmailExists') errorEmailExists()
      if (response.data.email) {
        const user = {
          email: values.email,
        }
        success(user)
      }
    })
  }

  return (
    <div className={styles.component}>
      <Form
        onSubmit={onSubmit}
        // initialValues={{ userType: "Trade" }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className='fields'>
              <div className='field-row'>
                <Field
                  name='email'
                  component='input'
                  type='email'
                  placeholder={tr.email}
                  required
                />
              </div>
              <div className='field-row'>
                <Field
                  name='password'
                  component='input'
                  type='password'
                  placeholder={tr.password}
                  required
                />
              </div>
            </div>
            <div className={'submit-wrap ' + (isLoading ? 'is-loading' : '')}>
              <div className='loader-wrap'>
                <ButtonLink
                  content={{
                    text: tr.submit,
                    linkType: 'submit',
                    isDisabled: isLoading,
                  }}
                />
                <div className='loader'>
                  <LoaderSvg />
                </div>
              </div>
            </div>
            <ul className='error-holder'>
              <li className='placeholder'>{tr.errorEmailExists}</li>
              <li className={error === 'emailExists' ? 'show' : ''}>
                {tr.errorEmailExists}
                <br />
                <LinkWrap to='/account'>{tr.backToLogin}</LinkWrap>
              </li>
            </ul>
          </form>
        )}
      />
    </div>
  )
}

export default Component
